import React from "react";
import Footer from "src/components/Footer_pt";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="disclosureText">
        </div>
        <div id="page">
          <div id="limit">
            <header id="header">
              <div className="mobileHeaderContainer clearfix">
                <a href="/pt/" className="logo">
                  Piz Buin
                </a>
                <button
                  id="btnShowMenu"
                  className="collapse-btn collapse-short-btn right"
                  data-target=".collapse-menu"
                  data-toggle="collapse"
                  type="button"
                />
              </div>
              <nav id="menu" className="collapse-menu collapse-short-menu">
                <ul className="nav-header">
                  <li className="first has-sub-nav">
                    <a className="es_header" href="/pt/nossos-produtos/" target="_self">
                      Produtos
                    </a>
                    <ul>
                      <li>
                        <a href="/pt/nossos-produtos/hydro-infusion/">
                          Hydro Infusion
                        </a>
                      </li>
                      {/* <li>
                        <a href="/pt/nossos-produtos/active-protect/">
                          Active &amp; Protect
                        </a>
                      </li> */}
                      <li>
                        <a href="/pt/nossos-produtos/moisturising/">
                          Moisturising
                        </a>
                      </li>
                      <li>
                        <a href="/pt/nossos-produtos/tan_protect/">
                          Tan &amp; Protect
                        </a>
                      </li>
                      {/* <li>
                        <a href="/pt/nossos-produtos/instantglow/">
                          Instant Glow
                        </a>
                      </li> */}
                      <li>
                        <a href="/pt/nossos-produtos/allergy/">Allergy</a>
                      </li>
                      <li>
                        <a href="/pt/nossos-produtos/aftersun/">After sun</a>
                      </li>
                      <li>
                        <a href="/pt/nossos-produtos/todos_os_Produtos/">
                          Todos os Produtos
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a className="es_header" href="/pt/a_nossa_historia/" target="_self">
                      A Nossa História
                    </a>
                  </li>
                  <li>
                    <a className="es_header" href="/pt/sobre_o_sol/" target="_self">
                      Aprenda Sobre o Sol
                    </a>
                  </li>
                  {/*<li className="nav-selected nav-path-selected">
                    <a className="es_header" href="/pt/Por-Onde-Andaste/" target="_self">
                      Por Onde Andaste 2019
                    </a>
    </li>*/}
                </ul>
                <ul className="countryNav">
                  <li
                    className="collapsible has-sub-nav"
                    style={{
                      "list-style": "none"
                    }}
                  >
                    <a className="countries" href="#">
                      PAÍSES
                    </a>
                    <ul className="subMenu">
                      <li>
                        <a href="/en/">INT</a>
                      </li>
                      <li>
                        <a href="/uk/">UK</a>
                      </li>
                      <li>
                        <a href="/es/donde-has-estado/">ES</a>
                      </li>
                      <li>
                        <a href="/pt/Por-Onde-Andaste/">PT</a>
                      </li>
                      <li>
                        <a href="/it/Ma-Dove-Sei-Stata/">IT</a>
                      </li>
                      <li>
                        <a href="/gr/Where-Have-You-Been/">GR</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
              <div id="menuExtra" />
            </header>
            <div className="page-heritage__intro"></div>
            <section id="content">
              <div id="loading" />
              <div className="innerContent">
                <div
                  id="blockStyle4067Content127"
                  className=" ccm-block-styles"
                >
                  <div id="HTMLBlock4067" className="HTMLBlock">
                    <table
                      border="0"
                      cellPadding="0"
                      cellSpacing="0"
                      width="100%"
                      className="w100pc"
                      bgcolor="#ffffff"
                    >
                      <tbody>
                        <tr>
                          <td
                            style={{
                              padding: "30px 20px 0px 20px"
                            }}
                          >
                            <h1
                              style={{
                                color: "#f68e27",
                                "-webkit-text-align": "center",
                                "text-align": "center",
                                "line-height": "130%"
                              }}
                            >
                              Um bronzeado deslumbrante tem sempre uma história
                              por contar
                            </h1>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div id="HTMLBlock3982" className="HTMLBlock">
                  <hr
                    style={{
                      width: "250px",
                      "border-color": "#f68e27",
                      "border-style": "solid",
                      margin: "60px auto 60px auto"
                    }}
                  />
                </div>
                <div
                  id="blockStyle4155Content120"
                  className=" ccm-block-styles"
                >
                  <div id="HTMLBlock4155" className="HTMLBlock">
                    <table
                      border="0"
                      cellPadding="0"
                      cellSpacing="0"
                      width="100%"
                      className="w100pc"
                      bgcolor="#ffffff"
                    >
                      <tbody>
                        <tr>
                          <td align="center" valign="top">
                            <table
                              border="0"
                              cellPadding="0"
                              cellSpacing="0"
                              width="100%"
                              className="w100pc"
                            >
                              <tbody>
                                <tr>
                                  <th
                                    align="left"
                                    valign="middle"
                                    style={{
                                      "font-weight": "normal",
                                      "vertical-align": "middle",
                                      margin: "0",
                                      padding: "0",
                                      width: "50%",
                                      "padding-left": "1.5%"
                                    }}
                                    className="block w100pc"
                                  >
                                    <table
                                      border="0"
                                      cellPadding="0"
                                      cellSpacing="0"
                                      width="100%"
                                      dir="ltr"
                                    >
                                      <tbody>
                                        <tr>
                                          <td className="side-pad">
                                            <table
                                              border="0"
                                              cellPadding="0"
                                              cellSpacing="0"
                                              width="100%"
                                            >
                                              <tbody>
                                                <tr>
                                                  <td
                                                    align="left"
                                                    style={{
                                                      "-webkit-text-transform":
                                                        "uppercase",
                                                      "text-transform":
                                                        "uppercase"
                                                    }}
                                                  >
                                                    <h3
                                                      style={{
                                                        "font-weight": "100",
                                                        margin: "0 0 25px"
                                                      }}
                                                    >
                                                      Quer um bronzeado
                                                      deslumbrante?
                                                      <br />
                                                      Certifique-se de que está
                                                      protegida!
                                                    </h3>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td align="left">
                                                    <p>
                                                      Durante muito tempo
                                                      existiu um mito falso,
                                                      segundo o qual não é
                                                      necessário aplicar
                                                      proteção solar na pele
                                                      para conseguir um
                                                      bronzeado deslumbrante e
                                                      rápido. É errado! A
                                                      radiação solar direta na
                                                      pele é perigosa para a
                                                      saúde, pode provocar
                                                      queimaduras solares e faz
                                                      com que o bronzeado
                                                      desapareça mais
                                                      rapidamente. Os efeitos
                                                      dos raios ultravioleta A e
                                                      B (UVA/UVB) provocam na
                                                      pele uma pigmentação
                                                      imediata mas temporária,
                                                      fotoenvelhecimento ou
                                                      cancro, daí a importância
                                                      da proteção.
                                                      <br />
                                                      <br />A nossa pele tem
                                                      vários mecanismos de
                                                      defesa contra as radiações
                                                      solares, sendo o principal
                                                      a produção de melanina.
                                                      Este pigmento é o
                                                      responsável pela cor da
                                                      nossa pele, cabelo e íris,
                                                      entre outros, e tem a
                                                      capacidade de filtrar de
                                                      forma natural os raios
                                                      solares prejudiciais.
                                                    </p>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </th>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div id="HTMLBlock3983" className="HTMLBlock">
                  <hr
                    style={{
                      width: "250px",
                      "border-color": "#f68e27",
                      "border-style": "solid",
                      margin: "60px auto 60px auto"
                    }}
                  />
                </div>
                <div
                  id="blockStyle4157Content121"
                  className=" ccm-block-styles"
                >
                  <div id="HTMLBlock4157" className="HTMLBlock">
                    <table
                      border="0"
                      cellPadding="0"
                      cellSpacing="0"
                      width="100%"
                      className="w100pc"
                      bgcolor="#ffffff"
                    >
                      <tbody>
                        <tr>
                          <td align="center" valign="top">
                            <table
                              border="0"
                              cellPadding="0"
                              cellSpacing="0"
                              width="100%"
                              className="w100pc"
                              dir="rtl"
                            >
                              <tbody>
                                <tr>
                                  <th
                                    align="left"
                                    valign="middle"
                                    style={{
                                      "font-weight": "normal",
                                      "vertical-align": "middle",
                                      margin: "0",
                                      padding: "0",
                                      width: "50%",
                                      "padding-left": "1.5%"
                                    }}
                                    className="block w100pc"
                                  >
                                    <table
                                      border="0"
                                      cellPadding="0"
                                      cellSpacing="0"
                                      width="100%"
                                      dir="ltr"
                                    >
                                      <tbody>
                                        <tr>
                                          <td align="center">
                                            <img
                                              style={{
                                                "vertical-align": "text-top",
                                                "max-width": "100%",
                                                "max-height": "500px",
                                                "margin-bottom": "-70px"
                                              }}
                                              src="/assets/images/tanprotect-aftersun_rgb.jpg"
                                              alt
                                            />
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </th>
                                  <th
                                    align="left"
                                    valign="middle"
                                    style={{
                                      "font-weight": "normal",
                                      "vertical-align": "middle",
                                      margin: "0",
                                      padding: "0",
                                      width: "50%",
                                      "padding-right": "1.5%"
                                    }}
                                    className="block w100pc"
                                  >
                                    <table
                                      border="0"
                                      cellPadding="0"
                                      cellSpacing="0"
                                      width="100%"
                                      dir="ltr"
                                    >
                                      <tbody>
                                        <tr>
                                          <td className="side-pad">
                                            <table
                                              border="0"
                                              cellPadding="0"
                                              cellSpacing="0"
                                              width="100%"
                                            >
                                              <tbody>
                                                <tr>
                                                  <td
                                                    align="left"
                                                    style={{
                                                      "-webkit-text-transform":
                                                        "uppercase",
                                                      "text-transform":
                                                        "uppercase"
                                                    }}
                                                  >
                                                    <h3
                                                      style={{
                                                        "font-weight": "100",
                                                        margin: "0 0 25px"
                                                      }}
                                                    >
                                                      Para um bronzeado
                                                      deslumbrante, mais
                                                      rápido... PIZ BUIN Tan
                                                      &amp; Protect
                                                      <span
                                                        style={{
                                                          "font-size": "15px",
                                                          "vertical-align":
                                                            "super",
                                                          "line-height": "0"
                                                        }}
                                                      >
                                                        ®
                                                      </span>{" "}
                                                      Spray Solar Intensificador
                                                      do bronzeado
                                                    </h3>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td align="left">
                                                    <p>
                                                      A sua fórmula combina uma
                                                      proteção UVA/UVB com
                                                      Illumitone
                                                      <span
                                                        style={{
                                                          "font-size": "10px",
                                                          "vertical-align":
                                                            "super",
                                                          "line-height": "0"
                                                        }}
                                                      >
                                                        TM
                                                      </span>
                                                      , uma tecnologia que
                                                      acelera e intensifica o
                                                      seu bronzeado natural*
                                                      durante mais tempo.{" "}
                                                      <i>*Testes ex vivo.</i> O
                                                      seu inovador formato em
                                                      spray permite uma
                                                      aplicação multiposições
                                                      360º. Este produto está
                                                      disponível com , FPS15 e
                                                      SPF 30.
                                                    </p>
                                                    <p
                                                      style={{
                                                        "-webkit-text-align":
                                                          "left",
                                                        "text-align": "left",
                                                        "margin-left": "30px"
                                                      }}
                                                    >
                                                      Todos os benefícios:
                                                    </p>
                                                    <ul
                                                      style={{
                                                        "-webkit-text-align":
                                                          "left",
                                                        "text-align": "left",
                                                        "list-style": "inherit",
                                                        "margin-left": "30px"
                                                      }}
                                                    >
                                                      <li
                                                        style={{
                                                          "list-style":
                                                            "inherit"
                                                        }}
                                                      >
                                                        Reforça o processo
                                                        natural de bronzeado da
                                                        pele*
                                                      </li>
                                                      <li
                                                        style={{
                                                          "list-style":
                                                            "inherit"
                                                        }}
                                                      >
                                                        Proteção UVA/UVB
                                                        imediata e eficaz
                                                      </li>
                                                      <li
                                                        style={{
                                                          "list-style":
                                                            "inherit"
                                                        }}
                                                      >
                                                        Rápida absorção
                                                      </li>
                                                      <li
                                                        style={{
                                                          "list-style":
                                                            "inherit"
                                                        }}
                                                      >
                                                        Fácil de aplicar
                                                      </li>
                                                      <li
                                                        style={{
                                                          "list-style":
                                                            "inherit"
                                                        }}
                                                      >
                                                        Fórmula não oleosa e não
                                                        pegajosa
                                                      </li>
                                                      <li
                                                        style={{
                                                          "list-style":
                                                            "inherit"
                                                        }}
                                                      >
                                                        Hidrata a pele (24
                                                        horas)
                                                      </li>
                                                      <li
                                                        style={{
                                                          "list-style":
                                                            "inherit"
                                                        }}
                                                      >
                                                        Ajuda a prevenir a
                                                        descamação e contribui
                                                        para um bronzeado mais
                                                        duradouro
                                                      </li>
                                                      <li
                                                        style={{
                                                          "list-style":
                                                            "inherit"
                                                        }}
                                                      >
                                                        Resistente à água e à
                                                        transpiração
                                                      </li>
                                                    </ul>
                                                    <p
                                                      style={{
                                                        margin: "20px 0 0 0"
                                                      }}
                                                    >
                                                      <a
                                                        href="/pt/nossos-produtos/tan_protect/"
                                                        target="_blank"
                                                        style={{
                                                          "-webkit-text-decoration":
                                                            "none",
                                                          "text-decoration":
                                                            "none"
                                                        }}
                                                      >
                                                        Descobre todos os
                                                        produtos da Piz Buin
                                                      </a>
                                                      <br />
                                                      E não te esqueças de
                                                      hidratar a tua pele depois
                                                      da exposição solar com a
                                                      <br />
                                                      <a
                                                        href="/pt/nossos-produtos/aftersun/#after-sun-al-vio-imediato-bruma-express-"
                                                        target="_blank"
                                                        style={{
                                                          color: "#f68e27"
                                                        }}
                                                      >
                                                        NOVA Bruma Express After
                                                        Sun
                                                      </a>
                                                    </p>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </th>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div id="HTMLBlock3984" className="HTMLBlock">
                  <hr
                    style={{
                      width: "250px",
                      "border-color": "#f68e27",
                      "border-style": "solid",
                      margin: "60px auto 60px auto"
                    }}
                  />
                </div>
                <div
                  id="blockStyle4013Content122"
                  className=" ccm-block-styles"
                >
                  <div id="HTMLBlock4013" className="HTMLBlock">
                    <table
                      border="0"
                      cellPadding="0"
                      cellSpacing="0"
                      width="100%"
                      className="w100pc"
                      bgcolor="#ffffff"
                    >
                      <tbody>
                        <tr>
                          <td align="center" valign="top">
                            <table
                              border="0"
                              cellPadding="0"
                              cellSpacing="0"
                              width="100%"
                              className="w100pc"
                            >
                              <tbody>
                                <tr>
                                  <th
                                    align="left"
                                    valign="middle"
                                    style={{
                                      "font-weight": "normal",
                                      "vertical-align": "middle",
                                      margin: "0",
                                      padding: "0",
                                      width: "50%",
                                      "padding-right": "1.5%",
                                      "padding-bottom": "6%"
                                    }}
                                    className="block w100pc"
                                  >
                                    <table
                                      border="0"
                                      cellPadding="0"
                                      cellSpacing="0"
                                      width="100%"
                                      dir="ltr"
                                    >
                                      <tbody>
                                        <tr>
                                          <td align="center">
                                            <img
                                              style={{
                                                "vertical-align": "text-top",
                                                "max-width": "50%"
                                              }}
                                              src="/assets/images/SUN_PICTURES_left.jpg"
                                              alt
                                              width="100%"
                                            />
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </th>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </section>
            <section id="social">
              <a
                href="https://www.facebook.com/PizBuinPortugal"
                target="_blank"
                className="fb"
              >
                &nbsp;
              </a>
              <a
                href="https://www.instagram.com/pizbuinportugal/"
                target="_blank"
                className="inst"
              >
                &nbsp;
              </a>
              <a
                href="https://www.youtube.com/channel/UCuRXMca5zj_v97YlGJip63A"
                target="_blank"
                className="yt"
              >
                &nbsp;
              </a>
            </section>
            <div className="clear">
              <br />
            </div>
            <Footer></Footer>
          </div>
          <div id="product-details-overlay" className="itemDescription"></div>
        </div>
        <script src="/assets/js/main.js" />
        <script src="/assets/js/vendor/jquery.fancybox.js" />
        <script src="/assets/js/vendor/jquery.fancybox-media.js" />
        <script src="/assets/js/vendor/iealert.min.js" />
       </div>
    );
  }
}

export default Page;
